import React from "react"
import Layout from "../components/layout"
import Image  from "../components/image"
import ContactForm from "../components/contact"
import SEO from "../components/seo"
import {Container,Row,Col,Card} from 'react-bootstrap'


const mobileDesktop =()=>{
    return(
    <Layout>
        <SEO title="CRM Software" />
        <div  className="homeAuto">
            <Image alt="home automation" filename="crm1.png"  />
                <div className="electricalImage">
                    <h1 style={{fontWeight:"bold"}} className="crmhead">Accelerate your success.</h1>
                    <h3 className="crmheadpara">Capture, organize, and properly track leads.</h3>
                </div>
        </div>
        <Container>
            <Row className="aboutrow">
                <Col sm={12} lg={6} className="manuPara1">
                    <h3 className="crmh3">CRM Software Development</h3>
                    <p>Trace Width CRM is served from the cloud, fundamentally designed to help you generate leads, acquire new customers, close deals sooner, and sell, service, and market smarter. It all adds up to growth and scalability that may ultimately require more office space.</p>
                    <p>Trace Width CRM is compact and easy-to-use. It doesn’t frustrate or slow your sales team, so no need for management to force them to use it. They will love and adopt it as it simply helps them track leads & sell more with less drag. It gives you a full view of your pipeline and sales department activities. You will have the assurance that every lead is being nurtured as well as every lead follow-up is being completed.</p>
                </Col>
                <Col sm={12} lg={6}>
                    <Image alt="crm" filename="images-inside_29.png"  />
                </Col>
            </Row>

            <Row className="aboutrow">
                <Col sm={12} lg={6}>
                    <Image alt="crm" filename="tw-crm.png"  />
                </Col>
                <Col sm={12} lg={6} className="manuPara1">
                    <p>Like a self-driving car, Trace Width CRM can intelligently drive your business processes, alert you if things are going off track, and even forecast the future to make sure you’re never off track. Focus on selling to your clients & let Trace Width CRM take care of the details.</p>
                    <p>Dashboards in CRM are instant snapshots of the most important sales activities. Each sales person can examine how many contracts they’ve won, how many clients haven’t been solicited yet, and even a sales person’s ratings or metrics in relation to other sales team members.</p>
                </Col>
            </Row>

            <Row>
                <Col sm={12} lg={4} >
                    <Card className="crmCard">
                        <Image alt="cloud" filename="cont1.png" style={{width:"116px"}} />
                        <div className="headerSpan">Contacts</div> 
                        <div className="crmPara">Set meetings, take notes, send emails, and place calls</div>
                    </Card>
                </Col>
                <Col sm={12} lg={4}>
                    <Card className="crmCard">
                        <Image alt="cloud" filename="cont2.png"  style={{width:"116px"}}/>
                        <div className="headerSpan">Opportunities</div> 
                        <div className="crmPara">Assign deals, set meetings, track performance, and forecast sales</div>
                    </Card>
                </Col>
                <Col sm={12} lg={4}>
                    <Card className="crmCard">
                        <Image alt="cloud" filename="cont3.png"  style={{width:"116px"}}/>
                        <div className="headerSpan">Projects</div> 
                        <div className="crmPara">The employee workload planning – finish more tasks with less resources.</div>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col sm={12} lg={4}>
                    <Card className="crmCard">
                        <Image alt="cloud" filename="cont4.png" style={{width:"116px"}} />
                        <div className="headerSpan">Inventory</div> 
                        <div className="crmPara">Manage products, services, and payments.</div>
                    </Card>
                </Col>
                <Col sm={12} lg={4}>
                    <Card className="crmCard">
                        <Image alt="cloud" filename="cont5.png"  style={{width:"116px"}}/>
                        <div className="headerSpan">Customer Portal</div> 
                        <div className="crmPara">Let customers manage cases, share files, view an FAQ and more.</div>
                    </Card>
                </Col>
                <Col sm={12} lg={4}>
                    <Card className="crmCard">
                        <Image alt="cloud" filename="cont6.png"  style={{width:"116px"}}/>
                        <div className="headerSpan">Reporting</div> 
                        <div className="crmPara">Segment data, create charts, and export CSV reports</div>
                    </Card>
                </Col>
            </Row>
            <br/>
                <p className="manuPara1">Versatile accessibility via desktop, laptop, mobile, tablet, etc. Divergent solutions with a flair to flourish with your business. Speed and ease of deployment (on-premise or in the cloud, including hosting), SME focus, and price.  All delivered in a intuitive and a user-oriented interface.</p>
             
        </Container>

            
        <div className="headerForm"> 
            <br/>
            <div className="homeCardrow5">Empower your company with the possibilities of a custom CRM from Trace Width!</div>
            <ContactForm  />
        </div>
          
    </Layout>
);

}

export default mobileDesktop;

